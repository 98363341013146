import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Switch, Stack, Typography } from '@mui/material';

import Boolean from '../Boolean';
import AdminEditUser from './AdminEditUser';
import AdminEditShowUser from './AdminEditShowUser';
import AdminInviteUser from './AdminInviteUser';
import AdminInviteDrumMajor from './AdminInviteDrumMajor';
import AdminDeleteUser from './AdminDeleteUser';
import getApi from '../../utils/getApi';

const WINDOW_LOCATION = getApi();

const CELL_STYLE = "flex-row justify-content-center d-flex align-items-center adminTextAlignCenter";

const SORT_ID = 0;
const SORT_LABEL = 1;
const SORT_EMAIL = 2;
const SORT_FIRST_NAME = 3;
const SORT_LAST_NAME = 4;
const SORT_IS_ADMIN = 5;
const SORT_IS_DRUM_MAJOR = 6;
const SORT_IS_VERIFIED = 7;
const SORT_ACTIVATED_DATE = 8;
const SORT_CREATED_DATE = 9;
const SORT_LOGIN_DATE = 10;
const SORT_UP = 1;
const SORT_DOWN = -1;

const AdminUsersPage = (props) => {
    const {token, users, setUsers, sections, shows, default_show} = props;

    const [showEditUser, setShowEditUser] = useState(false);
    const [showEditShowUser, setShowEditShowUser] = useState(false);
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [showCreateDrumMajor, setShowCreateDrumMajor] = useState(false);
    const [showDeleteUser, setShowDeleteUser] = useState(false);
    const [editUserData, setEditUserData] = useState({});
    const [sortBy, setSortBy] = useState(SORT_LABEL);
    const [sortDirection, setSortDirection] = useState(SORT_DOWN);

    // Show Users who aren't in the default show
    const [showOldUsers, setShowOldUsers] = useState(false);


    const dateFormat = (dateTime) => {
        if (dateTime == null) { return ""; }
        let date = new Date(Date.parse(dateTime));

        return date.toLocaleDateString();
    }

    const dateTimeFormat = (dateTime) => {
        if (dateTime == null) { return ""; }
        let date = new Date(Date.parse(dateTime));

        return date.toLocaleString();
    }

    const openEditUser = (user) => {
        // "JSON.parse(JSON.stringify(person))" Are there for deep copying 
        setEditUserData(JSON.parse(JSON.stringify(user)));
        setShowEditUser(true);
        setShowEditShowUser(false);
        setShowDeleteUser(false);
    }

    const openEditShowUser = (user) => {
        // "JSON.parse(JSON.stringify(person))" Are there for deep copying 
        setEditUserData(JSON.parse(JSON.stringify(user)));
        setShowEditUser(false);
        setShowEditShowUser(true);
        setShowDeleteUser(false);
    }

    const openDeleteUser = (user) => {
        // "JSON.parse(JSON.stringify(person))" Are there for deep copying 
        setEditUserData(JSON.parse(JSON.stringify(user)));
        setShowEditUser(false);
        setShowEditShowUser(false);
        setShowDeleteUser(true);
    }

    const getAllShowUserLabels = (user) => {
        if (!showOldUsers) {
            const showUsers = user["show_users"]

            for (let i = 0; i < showUsers.length; i++) {
                if (showUsers[i]["show_id"] === default_show["id"]) {
                    return showUsers[i]["label"];
                }
            }
            return "";
        }

        let out = "";

        for (let i = 0; i < user.show_users.length; i++) {
            out = out + user.show_users[i].label;
            if (i !== user.show_users.length - 1) { out += ", " }
        }

        return out;
    }

    const updateUser = (userData) => {
        let newUsers = JSON.parse(JSON.stringify(users));

        for (let i = 0; i < newUsers.length; i++) {
            if (newUsers[i].id === userData.id) {
                for (const key in newUsers[i]) {
                    newUsers[i][key] = userData[key];
                }
            }
        }

        setUsers(newUsers);
    }

    const handleSave = (userData) => {
        try {
            fetch(WINDOW_LOCATION + '/update-users', {
                method: 'POST',
                body: JSON.stringify(userData),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'Authorization': 'Bearer ' + token
                }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        setShowEditShowUser(false);
                        setShowEditUser(false);
                        updateUser(userData);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        console.log(error);
                        alert(error)
                    }
                );
        } catch (error) {
            console.log("ERROR " + error);
        }
    }

    const sortLabels = (data) => {
        return data.sort(function(a, b) {
            let keyA = getAllShowUserLabels(a).match(/(\d+)/);
            let keyB = getAllShowUserLabels(b).match(/(\d+)/);
            let keyAPrefix = getAllShowUserLabels(a).replace(/[0-9]/g, '');
            let keyBPrefix = getAllShowUserLabels(b).replace(/[0-9]/g, '');
            
            let oppDir = sortDirection === SORT_UP ? SORT_DOWN : SORT_UP;

            // Compare the letter "prefixes" first
            if (keyAPrefix < keyBPrefix) return sortDirection;
            if (keyAPrefix > keyBPrefix) return oppDir;

            // Compare the numbers
            if (parseInt(keyA) < parseInt(keyB)) return sortDirection;
            if (parseInt(keyA) > parseInt(keyB)) return oppDir;

            return 0;
        });
    }

    const getDefaultShowUser = (user) => {
        const showUsers = user["show_users"]
        for (let i = 0; i < showUsers.length; i++) {
            if (showUsers[i]["show_id"] === default_show["id"]) {
                return showUsers[i];
            }
        }
        return "";
    }

    const sortDrumMajor = (users) => {
        return users.sort(function(a, b) {
            let aSU = getDefaultShowUser(a);
            let bSU = getDefaultShowUser(b);

            let keyA = aSU["is_drum_major"] !== null ? aSU["is_drum_major"] : false;
            let keyB = bSU["is_drum_major"] !== null ? bSU["is_drum_major"] : false;

            let oppDir = sortDirection === SORT_UP ? SORT_DOWN : SORT_UP;
            if (keyA === false && keyB === true) return oppDir;
            if (keyA === true && keyB === false) return sortDirection;

            return 0;
        });
    }

    const sort = (data) => {
        if (sortBy === SORT_LABEL) { return sortLabels(data); }

        if (sortBy === SORT_IS_DRUM_MAJOR)  {
            return sortDrumMajor(data);
        }
        
        let useKey = "";

        if (sortBy === SORT_ID)             { useKey = "id";            }
        if (sortBy === SORT_EMAIL)          { useKey = "email";         }
        if (sortBy === SORT_FIRST_NAME)     { useKey = "first_name";    }
        if (sortBy === SORT_LAST_NAME)      { useKey = "last_name";     }
        if (sortBy === SORT_IS_ADMIN)       { useKey = "is_admin";      }
        if (sortBy === SORT_IS_VERIFIED)    { useKey = "verified_date"; }
        if (sortBy === SORT_ACTIVATED_DATE) { useKey = "activated_date";}
        if (sortBy === SORT_CREATED_DATE)   { useKey = "created_date";  }
        if (sortBy === SORT_LOGIN_DATE)     { useKey = "last_login";  }

        return data.sort(function(a, b) {
            let keyA = a[useKey] !== null ? a[useKey] : "";
            let keyB = b[useKey] !== null ? b[useKey] : "";

            let oppDir = sortDirection === SORT_UP ? SORT_DOWN : SORT_UP;
            if (keyA < keyB) return oppDir;
            if (keyA > keyB) return sortDirection;

            return 0;
        });
    }

    const handelHeaderClick = (id) => {
        if (id !== sortBy){
            setSortBy(id);
            setSortDirection(SORT_DOWN);
        } else {
            setSortDirection(sortDirection === SORT_UP ? SORT_DOWN : SORT_UP);
        }

    }

    /**
     * Based on the showOldUsers state, only return either the users in the default show or all users
     * @param {Array} users
     * @returns {Array} filtered users
     */
    const getOnlyShownUsers = (users) => {
        if (showOldUsers) {
            return users;
        }

        return users.filter(function(user) {
            if (user["is_admin"]) {
                return true;
            }

            const showUsers = user["show_users"]

            for (let i = 0; i < showUsers.length; i++) {
                if (showUsers[i]["show_id"] === default_show["id"]) {
                    return true;
                }
            }
            return false;
        });
    }


    return(
        <>
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Show Old Users</Typography>
            <Switch
                checked={showOldUsers}
                onChange={(e) => setShowOldUsers(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
                size='xl'
            />
        </Stack>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th onClick={() => handelHeaderClick(0)}>ID</th>
                    <th onClick={() => handelHeaderClick(1)}>Labels</th>
                    <th onClick={() => handelHeaderClick(2)}>Email</th>
                    <th onClick={() => handelHeaderClick(3)}>First Name</th>
                    <th onClick={() => handelHeaderClick(4)}>Last Name</th>
                    <th onClick={() => handelHeaderClick(5)}>Is Admin</th>
                    <th onClick={() => handelHeaderClick(6)}>Is Drum Major</th>
                    <th onClick={() => handelHeaderClick(7)}>Is Verified</th>
                    <th onClick={() => handelHeaderClick(8)}>Activated Date</th>
                    <th onClick={() => handelHeaderClick(9)}>Created Date</th>
                    <th onClick={() => handelHeaderClick(10)}>Last Login</th>
                    <th>Edit</th>
                    <th>Edit Show Users</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
                {
                    sort(getOnlyShownUsers(users)).map((user, index) => 
                        <tr key={index}>
                            <td><div className={CELL_STYLE}> {user.id} </div></td>
                            <td><div className={CELL_STYLE}> {getAllShowUserLabels(user)} </div></td>
                            <td><div className={CELL_STYLE}> {user.email} </div></td>
                            <td><div className={CELL_STYLE}> {user.first_name} </div></td>
                            <td><div className={CELL_STYLE}> {user.last_name} </div></td>
                            <td><div className={CELL_STYLE}> <Boolean state={user.is_admin}/> </div></td>
                            <td><div className={CELL_STYLE}> <Boolean state={getDefaultShowUser(user).is_drum_major}/> </div></td>
                            <td><div className={CELL_STYLE}> <Boolean state={user.verified_date !== null}/> </div></td>
                            <td><div className={CELL_STYLE}> {dateFormat(user.activated_date)} </div></td>
                            <td><div className={CELL_STYLE}> {dateFormat(user.created_date)} </div></td>
                            <td><div className={CELL_STYLE}> {dateTimeFormat(user.last_login)} </div></td>
                            <td><div className={CELL_STYLE}>
                                <button className='btn btn-success' onClick={(e) => openEditUser(user)}>Edit</button> 
                            </div></td>
                            <td><div className={CELL_STYLE}> 
                                <button className='btn btn-primary' onClick={(e) => openEditShowUser(user)}>Edit</button> 
                            </div></td>
                            <td><div className={CELL_STYLE}> 
                                <button className='btn btn-danger' onClick={(e) => openDeleteUser(user)}>Delete</button> 
                            </div></td>
                        </tr> 
                )
                }
                
                <AdminEditUser 
                    showEditUser={showEditUser} 
                    setShowEditUser={setShowEditUser} 
                    editUserData={editUserData} 
                    setEditUserData={setEditUserData}
                    handleSave={handleSave}
                />
                <AdminEditShowUser 
                    showEditShowUser={showEditShowUser} 
                    setShowEditShowUser={setShowEditShowUser} 
                    editUserData={editUserData} 
                    setEditUserData={setEditUserData}
                    sections={sections}
                    shows={shows}
                    handleSave={handleSave}
                />
                <AdminInviteUser 
                    show={showCreateUser}
                    setShow={setShowCreateUser}
                    token={token}
                />
                <AdminInviteDrumMajor 
                    show={showCreateDrumMajor}
                    setShow={setShowCreateDrumMajor}
                    token={token}
                    default_show_id={default_show["id"]}
                />
                <AdminDeleteUser 
                    show={showDeleteUser}
                    setShow={setShowDeleteUser}
                    user={editUserData}
                    token={token}
                />
                
            </tbody>
        </Table>
        <div className='d-flex flex-column justify-content-start align-items-center' style={{bottom: "1%", left: "1%", position: "fixed"}}>
            <button 
                className="btn btn-success" 
                onClick={() => setShowCreateUser(true)}
            >Create Admin</button>
            <button 
                className="btn btn-primary" 
                onClick={() => setShowCreateDrumMajor(true)}
            >Create Drum Major</button>
        </div>
        </>
    );
};

export default AdminUsersPage;